
import {
  Vue,
  Component,
  Prop,
  PropSync,
} from 'vue-property-decorator';
import { Property, Value } from '@/types/vjsf';
import {
  convertToDateTimeToLocaleString,
  convertToISOString,
} from '@/helpers/converters/date';

// Lazy loading imports
const DatePicker = () => import(
  /* webpackChunkName: "form-date-picker" */
  /* webpackPrefetch: false */
  '@/components/formElements/elements/DatePicker.vue'
);
const Tooltip = () => import(
  /* webpackChunkName: "form-tooltip" */
  /* webpackPrefetch: false */
  '@/components/Tooltip/Tooltip.vue'
);

@Component({
  name: 'DateField',
  components: {
    DatePicker,
    Tooltip,
  },
})
export default class DateField extends Vue {
  @PropSync('value', { required: true })
  private dateTime!: Value;

  @Prop({ required: true })
  private readonly property!: Property;

  @Prop({ required: true })
  private readonly locale!: string;

  @Prop({ required: true })
  private readonly id!: string;

  @Prop({ required: true })
  private readonly required!: boolean;

  protected reset(): void {
    this.dateTime = null;
  }

  protected setToday(): void {
    const date: Date = new Date();
    date.setHours(0, 0, 0, 0);
    this.dateTime = convertToISOString(date);
  }

  formatDate(dateInput: string|null): null|string {
    if (typeof dateInput === 'string') {
      const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
      };
      return convertToDateTimeToLocaleString(dateInput, this.locale, options);
    }
    return dateInput;
  }
}
